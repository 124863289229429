<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Content from '@/services/Content'
import Swal from "sweetalert2";
import modalUpdatePinFeed from '@/components/modals/news/modalUpdatePinFeed';
import modalEditVerticalImage from '@/components/modals/news/modalEditVerticalImage';



export default {
  components: { Layout, PageHeader, modalUpdatePinFeed, modalEditVerticalImage},
  page: {
    title: "Business News",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Feed",
      items: [
        {
          text: "News",
        },
        {
          text: "Feed",
          active: true,
          href: "/news/sport_news_feed",
        },
      ],
      showRefresh:true,
      showAlert: false,
      isBusyButton: false,
      isBusy: false,
      tryingToSubmit:false,
      showLoader:false,
      id: this.$route.params.id,
      tableData: [],
      pinDate: "",
      verticalImage: "",
      currentPage: 1,
      perPage: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
    };
  },
  computed: {

    totalRows() {
      return this.tableData.length;
    },
    currentImage() {
      return this.tableData[this.currentPage - 1] || null;
    },
    isPinned() {
      if (!this.currentImage || !this.currentImage.pin_end_time) {
        return false;
      }
      return new Date(this.currentImage.pin_end_time) >= new Date();
    }
  },
  methods: {

    async getNewsFeed() {
      this.showLoader =  true

      try {
        this.toggleBusy();
        const response = await Content.getNewsFeed();

        if (response.data.feed && response.data.feed.length > 0) {
          this.tableData = response.data.feed;
          if (response.data.pinned && response.data.pinned.length > 0) {
            this.tableData = [...response.data.pinned, ...this.tableData];
          }
        } else {
          this.tableData = [];
        }
        this.toggleBusy();
      } catch (error) {
        console.error("Error fetching news feed:", error);
        this.tableData = [];
      }finally {
        this.showLoader =  false
      }
    },

    async handlePinAction() {
      if (this.tryingToSubmit || this.showLoader) return;

      if (this.isPinned) {
        const today = new Date();
        const formattedDate = today.toISOString().split("T")[0];

        await this.submitForm(formattedDate);
      } else {
        this.callModalUpdatePinFeed(this.currentImage);
      }
    },

    async submitForm(pinEndTime) {
      this.tryingToSubmit = true;
      this.submitted = true;



      try {
        let formData = new FormData();
        formData.append('pin_end_time', pinEndTime);
        formData.append('type', this.currentImage.type);
        formData.append('content_type', this.currentImage.content_type);
        formData.append('csrf_token', this.csrf_token);
        formData.append('_method', 'PATCH');
        const response = await Content.updateFeed(this.currentImage.id, formData);
        if (response.success === false) {
          Swal.fire("Error", response.error || "Failed to Unpin Feed. Please try again.", "error");
        } else {
          Swal.fire("Success", "Unpin successfully!", "success");
          this.getNewsFeed();
        }

      } catch (error) {
        console.error("Failed to create brand:", error);
        Swal.fire("Error", error.response?.data?.error || "Failed to Unpin Feed. Please try again.", "error");
      } finally {
        this.tryingToSubmit = false;
        this.showLoader = false;
      }
    },
    handleClick() {
      if (this.currentImage?.content_type === "video" || this.currentImage?.content_type === "short") {
        window.open(this.currentImage.videourl, "_blank");
      } else if (this.currentImage?.link) {
        window.open(this.currentImage.link, "_blank");
      }
    },
    decodeHtmlEntities(str) {
      let doc = new DOMParser().parseFromString(str, "text/html");
      return doc.documentElement.textContent;
    },
    callModalUpdatePinFeed(currentImage) {
      this.pinDate = currentImage;
      this.$bvModal.show("update_pin");
    },
    callModalEditVerticalImage(currentImage) {
      this.verticalImage = currentImage;
      this.$bvModal.show("update_vertical_image");
    }


  },
  created() {
    this.getNewsFeed();
  },
  middleware: "authentication",
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <template v-if="!showLoader">
    <div class="row mb-4 p-3">
      <div class="col-xl-4">
        <div class="card mh-50">
          <div class="card-body">
            <div class="text-center">
              <div class="d-flex flex-column align-items-center">
                <!-- Display one image per page -->
                <div v-if="currentImage" class="image-container" @click="handleClick">
                  <img
                      :src="currentImage.vertical_image"
                      class="mobile-frame"
                      :alt="currentImage.title || 'Image'"
                  />

                  <!-- Show Play Icon if it's a video -->
                  <div v-if="currentImage && (currentImage.content_type === 'video' || currentImage.content_type === 'short')" class="play-icon">
                    ▶
                  </div>
                  <!-- Bottom-Left Overlay -->
                  <div class="image-overlay">
                    <span class="image-type">{{ currentImage.content_type }}</span>
                    <h5 class="image-title">{{ currentImage.title }}</h5>
                    <span class="image-date">{{ currentImage.published }}</span>
                  </div>
                </div>

                <!-- Numbered Pagination -->
                <div class="dataTables_paginate paging_simple_numbers float-end mt-3">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="center"
                      size="md"
                      keys="false"
                      class="pagination pagination-rounded mb-0"
                      hide-goto-end-buttons
                      hide-ellipsis
                      limit="10"

                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8">


        <div class="card h-100">
          <div class="card-body">
            <button
                @click="handlePinAction"
                :class="{ 'btn-primary': isPinned, 'btn-success': !isPinned }"
                class="btn float-end"
                :disabled="tryingToSubmit || showLoader"
            >
              {{ isPinned ? "Unpin" : "Pin" }}
              <b-spinner v-show="tryingToSubmit" small></b-spinner>
            </button>
            <h4 class="text-center">Feed Item</h4>

            <p v-if="currentImage.title"><strong>Title: </strong>{{ decodeHtmlEntities(currentImage.title) }}</p>

            <p v-if="currentImage.link"><strong>Link:</strong> <a :href="currentImage.link"  target="_blank">
              {{ currentImage.link }}
            </a></p>

            <p><strong  v-if="currentImage.image">Image:</strong> <a :href="currentImage.image" target="_blank">
              {{ currentImage.image }}
            </a></p>

            <p v-if="currentImage.source_id"><strong>Source ID: </strong>{{ decodeHtmlEntities(currentImage.source_id) }}</p>

            <p><strong  v-if="currentImage.original_link">Original Link:</strong> <a :href="currentImage.original_link" target="_blank">
              {{ currentImage.original_link }}
            </a></p>

            <p v-if="currentImage.published"><strong>Published: </strong>{{ currentImage.published }}</p>

            <p v-if="currentImage.type"><strong>Type: </strong>{{ currentImage.type }}</p>

            <p v-if="currentImage.content_type"><strong>Content Type: </strong>{{ currentImage.content_type }}</p>

            <p v-if="currentImage.vertical_image">
              <strong>Vertical Image: </strong>
              <img :src="currentImage.vertical_image" alt="Vertical Image" style="max-width: 100px; max-height: 100px;">

              <a href="javascript:void(0);" class="px-2 text-primary"
                 v-b-tooltip.hover title="Edit"
                 @click="callModalEditVerticalImage(currentImage)">
                <i class="uil uil-pen font-size-18"></i>
              </a>
            </p>


            <p v-if="currentImage.pin_end_time">
              <strong>Pin End Time: </strong>{{ currentImage.pin_end_time }}
            </p>



          </div>
        </div>


      </div>
    </div>
</template>
    <template v-else>
      <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
        <b-spinner large></b-spinner>
      </div>
    </template>
    <modalUpdatePinFeed @onRefresh="getNewsFeed()"  :pinDate="pinDate" ></modalUpdatePinFeed>
    <modalEditVerticalImage @onRefresh="getNewsFeed()"  :verticalImage="verticalImage" ></modalEditVerticalImage>

  </Layout>
</template>

<style scoped>
/* Image container */
.image-container {
  width: 250px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: black;
  border-radius: 30px;
  padding: 10px;
  overflow: hidden;
  cursor: pointer; /* Makes it clear it's clickable */
}

/* Mobile phone styled image */
.mobile-frame {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

/* Play icon styling */
.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 15px 25px;
  border-radius: 50%;
  font-weight: bold;
}

/* Bottom-Left Overlay */
.image-overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  padding: 10px;
  text-align: left;
  max-width: 90%;
}

/* Badge Styling for .image-title and .image-type */
.image-type {
  background-color: #007bff; /* Blue background */
  color: white;
  padding: 1px 5px;
  border-radius: 30px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  font-size: 12px; /* Adjust size as needed */
  margin-bottom: 10px;
}

/* Slightly smaller date text */
.image-date {
  font-size: 12px; /* Smaller font size */
  color: rgba(255, 255, 255, 0.7); /* Lighter color */
  text-shadow: none;
}
.image-title {
  color: white;
}

/* Play icon adjustments */
.play-icon {
  padding: 10px 20px !important;
  font-size: 30px !important;
}
.content-box {
  background-color: #f9f9f9; /* Light background for readability */
  padding: 20px; /* Add padding around the content */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for a card-like effect */
  font-size: 14px; /* Slightly larger font size for better readability */
  line-height: 1.6; /* Improved line spacing */
  color: #333; /* Dark text color for readability */
  margin-top: 15px; /* Add margin above the content box */
}

/* Optional: Adjust links inside content */
.content-box a {
  color: #007bff; /* Blue color for links */
  text-decoration: none; /* Remove underline */
  font-weight: bold;
}

.content-box a:hover {
  text-decoration: underline; /* Underline links on hover */
}

</style>
